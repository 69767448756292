var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'styled-table-container': true,
        'hide-margins': _vm.hideMargins
    }},[(!_vm.hideHeader)?_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-field"},[_c('v-text-field',{staticClass:"styled-field styled-field-small has-icon",attrs:{"prepend-inner-icon":"search","type":"string","label":_vm.searchText,"flat":"","hide-details":"","small":"","multiple":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"table-field"},[_c('v-select',{staticClass:"styled-field styled-field-small",attrs:{"items":_vm.headers,"value":_vm.internalPagination.sortBy,"item-text":"text","item-value":"value","label":"Sort by","flat":"","hide-details":"","small":"","clearable":"","dense":""},on:{"input":function($event){return _vm.changeSort($event)}}})],1),_vm._t("filters")],2):_vm._e(),_c('v-data-table',_vm._b({ref:"table",staticClass:"styled-table",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.filteredItems,"item-key":_vm.itemKey,"pagination":_vm.internalPagination,"rows-per-page-items":_vm.rowsPerPageItems,"hide-actions":_vm.hideActions},on:{"update:pagination":_vm.onUpdatePagination},scopedSlots:_vm._u([{key:"headers",fn:function({ headers }){return [_c('tr',_vm._l((headers),function(header,index){return _c('th',{key:`${header.text}-${index}`,class:[
                        header.sortable ? 'column sortable' : '',
                        _vm.internalPagination.descending ? 'desc' : 'asc',
                        header.value === _vm.internalPagination.sortBy ? 'active' : '',
                        !!header.value ? `table-header-${header.value}` : ''
                    ],style:({
                        textAlign: header.align,
                        width: header.width
                    }),on:{"click":function($event){(!!header.value && header.sortable) ? _vm.changeSort(header.value) : null}}},[(header.text)?_c('span',[(header.sortable)?_c('v-icon',{key:`${header.text}-icon`,class:header.align === 'right' ? 'arrow-right': '',attrs:{"small":""}},[_vm._v(" arrow_upward ")]):_vm._e(),(header.tooltip)?_c('styled-tooltip',{attrs:{"max-width":300,"position":"top"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(header.tooltip)}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(header.text)+" ")]):[_c('div',{class:{'bottom-border': header.text_bottom }},[_vm._v(" "+_vm._s(header.text)+" ")])]],2):_vm._e()])}),0),(_vm.hasFilters)?_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.text,style:({
                        width: header.width
                    }),attrs:{"align":"right"}},[(header.filter)?_c('div',[(header.filter === 'multiple')?_c('v-select',{class:`styled-field styled-field-small table-filter-${header.value}`,attrs:{"items":_vm.columnValueList(header),"flat":"","hide-details":"","small":"","multiple":"","clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function({ index }){return [(index === 0)?_c('span',{staticClass:"filterd-field"},[_c('icon',{attrs:{"name":"funnel","width":"15","height":"15"}})],1):_vm._e()]}}],null,true),model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}}):(header.filter === 'search')?_c('v-text-field',{staticClass:"styled-field styled-field-small",attrs:{"type":"string","label":""},model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}}):_vm._e()],1):_vm._e(),(header.text_bottom)?_c('div',[_vm._v(" "+_vm._s(header.text_bottom)+" ")]):_vm._e()])}),0):_vm._e()]}},{key:"items",fn:function(props){return [_vm._t("items",null,null,props)]}},(_vm.hasTotals)?{key:"footer",fn:function(){return [_c('tr',_vm._l((_vm.totals),function(total,index){return _c('td',{key:index,staticClass:"column-total",style:({
                        textAlign: total.header.cellAlign || 'left',
                    })},[_c('copy-text',{attrs:{"value":total.value,"show-text-only":""}},[_vm._v(" "+_vm._s(total.formatted)+" ")]),(total.average_value)?_c('div',[_c('copy-text',{attrs:{"value":total.average_value,"show-text-only":""}},[_c('div',{staticClass:"average"},[_vm._v(" Avg. "+_vm._s(total.average_formatted)+" ")])])],1):_c('div',{staticClass:"average"},[_vm._v(" ")])],1)}),0)]},proxy:true}:(!!_vm.$slots['footer'])?{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}:null,(!!_vm.$slots['expand'])?{key:"expand",fn:function(){return [_vm._t("expand")]},proxy:true}:null],null,true)},'v-data-table',_vm.appendedTableProps,false),[_c('template',{slot:"no-data"},[_c('div',{staticClass:"no-results"},[(!_vm.items.length)?_c('h4',[_vm._v(" No results found ")]):_vm._e()])]),_c('template',{slot:"progress"},[_c('loader')],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }